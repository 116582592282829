import './App.css';
import React from "react";
import { useState, useEffect } from 'react';
import { Routes, Route, useLocation} from "react-router-dom";
import "./App.css";


//Components
import NavBar from "./components/navBar/navBar";
import Footer from "./components/Footer/footer";

//Pages
import Home from "./pages/home/home";

const App = ()=> {
  return (
    <div className="App">
      <NavBar/>
       <Routes>
         <Route path="/" element= { <Home/> }/>
       </Routes>
       <Footer/>
    </div>
  );
}

export default App;
