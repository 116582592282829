import React from 'react'
import './navBar.css';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router";

//images
import logo from "../../images/PDD_Logo_Small.png";
import menuIcon from "../../images/BurgerMenu.svg";
import closeIcon from "../../images/closex.svg";


const NavBar = () =>{

	const [dropdown, setDropdown] = useState(false);
	const [useDropDownMenu, setUseDropDownMenu] = useState(false);
  	const navigate = useNavigate();
  	const [smallStyle, setSmallStyle] = useState(false);

	useEffect(()=>{

		window.addEventListener('resize', (event)=>{
			let screenWidth = document.documentElement.clientWidth;

			if (screenWidth <= 1200)			
			{
				setSmallStyle(true)
			}
			else
			{
				setSmallStyle(false);
			}
	    });

	}, [smallStyle])

	  
	//On initial window load
	useEffect(()=>{
	    
		let screenWidth = document.documentElement.clientWidth;

	    if (screenWidth <= 1200)
	    {
	      setSmallStyle(true)
	    }
	    else
	    {
	      setSmallStyle(false);
	    }

	}, [smallStyle])

  	const ShowHideDropDown = () =>{
		let show = !dropdown;

		console.log(`Showing Drop Down: ${show}`);

		setDropdown(show);
	}

	const GoToPage = (url) =>{
    	navigate(url);
    	setDropdown(false);
    	window.scrollTo(0,0);
  	}

  	const GoToSection = (url, section) =>{
	    navigate(url);
	    setDropdown(false);

	    setTimeout(()=>{

	      let offsetVariable = 80;

	      if (smallStyle)
	      {
	        offsetVariable = 50;
	      }

	      let posY = document.getElementById(section).offsetTop - offsetVariable;

	      window.scrollTo(0, posY)

	    }, 300);
	  }

  	useEffect(()=>{
		//console.log(`Dropdown state : ${dropdown}`)

		if (dropdown)
		{
		  document.addEventListener('mousedown', (event)=>{
		    //console.log(event.srcElement.id);
		    if (event.srcElement.id !== "NavDropDown")
		    {
		      setDropdown(false)
		    }
		  });
		}

	}, [dropdown]);

	useEffect(()=>{

		window.addEventListener('resize', (event)=>{
			let screenWidth = document.documentElement.clientWidth;

			//console.log(screenWidth);

			if (screenWidth <= 1200)
			{
				setUseDropDownMenu(true)
			}
			else
			{
				setUseDropDownMenu(false);
			}
		});

	}, [useDropDownMenu])

	
	//On initial window load
	useEffect(()=>{
		
		let screenWidth = document.documentElement.clientWidth;

		//console.log(screenWidth);

		if (screenWidth <= 1200)
		{
			setUseDropDownMenu(true)
		}
		else
		{
			setUseDropDownMenu(false);
		}

	}, [useDropDownMenu])

  	const NavDropDown = ()=>{
		return (
		  <div className="NavbarDropDown">
		    <img className="menuIcon" src={dropdown ? closeIcon : menuIcon} onClick={() => {
		      setDropdown((prev) => !prev)
		    }}/>
		    <nav className={`HideDropDownNav ${dropdown ? "DropDownNav" : ""}`} id="NavDropDown">
		      <ul>
		        <li onClick={()=>{GoToPage("/")}} id="NavDropDown"><label id="NavDropDown">Home</label></li>
		        <li onClick={()=>{GoToSection("/", "PDDIntro")}} id="NavDropDown"><label id="NavDropDown">Introduction</label></li>
		        <li onClick={()=>{GoToSection("/", "ValueProposition")}} id="NavDropDown"><label id="NavDropDown">Value Proposition</label></li>
		        <li onClick={()=>{GoToSection("/", "OurProducts")}} id="NavDropDown"><label id="NavDropDown">Our Products</label></li>
		        <li onClick={()=>{GoToSection("/", "KeyBenefits")}} id="NavDropDown"><label id="NavDropDown">Key Benefits</label></li>
		        <li onClick={()=>{GoToSection("/", "MarketFootPrint")}} id="NavDropDown"><label id="NavDropDown">Market Footprint</label></li>
		        <li onClick={()=>{GoToSection("/", "DistributionCapabilities")}} id="NavDropDown"><label id="NavDropDown">Distribution Capabilities</label></li>
		        <li onClick={()=>{GoToSection("/", "ContactUs")}} id="NavDropDown"><label id="NavDropDown">Contact Us</label></li>
		      </ul>
		    </nav>

		 	<img src={logo} className="DropDownPasarnowLogo" onClick={()=>{GoToPage("/")}}/>
		  </div>
		)
	}

	return(
		<div>
		{ !useDropDownMenu ?
			<div className="navbar">
				<nav className="navcontrols">
					<ul>
						<li onClick={()=>{GoToPage("/")}}><img src={logo} className="logo"/></li>
						<li onClick={()=>{GoToSection("/", "PDDIntro")}}>Intro</li>
						<li onClick={()=>{GoToSection("/", "ValueProposition")}}>Proposition</li>
						<li onClick={()=>{GoToSection("/", "OurProducts")}}>Products</li>
						<li onClick={()=>{GoToSection("/", "KeyBenefits")}}>Benefits</li>
						<li onClick={()=>{GoToSection("/", "MarketFootPrint")}}>Market</li>
						<li onClick={()=>{GoToSection("/", "DistributionCapabilities")}}>Capabilities</li>
						<li onClick={()=>{GoToSection("/", "ContactUs")}}>Contact Us</li>
					</ul>
				</nav>
			</div>	
			:
			<NavDropDown/>
		}
		</div>	
		
	)
}


export default NavBar;