import React from 'react';
import './home.css';
import { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";

//images
import warehouseLaptop from "../../images/warehouse_laptop.jpg";
import eyesOnAgility from "../../images/eyes_on_agility.jpg";
import trustwortyAndReliable from "../../images/Trustworthy_And_Reliable.jpg";
import qualityFocused from "../../images/Quality_Focused.jpg";
import pddLogoWhite from "../../images/PDD_Logo.png";


import valueProposition1 from "../../images/Value_Proposition_1.png";
import valueProposition2 from "../../images/Value_Proposition_2.png";
import valueProposition3 from "../../images/Value_Proposition_3.png";
import marketFootprintHoreca from "../../images/market_footprint_horeca.png";
import marketFootprintModernTrade from "../../images/market_footprint_modernTrade.png";
import marketFootprintOnline from "../../images/market_footprint_onlineDigital.png";
import marketFootprintPointOfInterest from "../../images/market_footprint_pointOfInterest.png";
import marketFootprintSpecialtyStore from "../../images/market_footprint_specialtyStore.png";
import marketFootprintKoperasi from "../../images/market_footprint_koperasi.png";
import marketFootprintGeneralTrade from "../../images/market_footprint_generalTrade.png";

import warehouseIcon from "../../images/central_warehouse_icon.png";
import coverageIcon from "../../images/coverage_area_icon.png";
import fleetIcon from "../../images/internal_fleet_icon.png";
import salesIcon from "../../images/sales_person_icon.png";
import pasarnowVan from "../../images/pasarnow_van.png";
import productImage from "../../images/products.png";


const Distributorship = ()=>{
	const [smallStyle, setSmallStyle] = useState(false);

	useEffect(()=>{

		window.addEventListener('resize', (event)=>{
			let screenWidth = document.documentElement.clientWidth;

			if (screenWidth <= 1200)
			{
				setSmallStyle(true)
			}
			else
			{
				setSmallStyle(false);
			}
		});

	}, [smallStyle])

	
	//On initial window load
	useEffect(()=>{
		
		let screenWidth = document.documentElement.clientWidth;

		if (screenWidth <= 1200)
		{
			setSmallStyle(true)
		}
		else
		{
			setSmallStyle(false);
		}

	}, [smallStyle])

	return(
		<div className={!smallStyle ? "Distributorship" : "DistributorshipSmall"}>

			<Helmet>
				<title>PDD- Pasarnow Distribusi Digital</title>
				<meta name="description" content="Pasarnow Distribusi Digital (“PDD”) is a digitally-enabled distribution company that collaborates with leading manufacturers in Indonesia to distribute quality products to various types of customers. We help Brands & Principals penetrate alternative & mass-market channels more effectively ." />
				<meta name="keywords" content="PDD, Online Market, Distributor, Pasarnow, Health, Personal Care, Snacks, Food, Beverages, Baby Products, Children's Products, Pet Food, Pet Supplies" />
			</Helmet>

			<div className={!smallStyle ? "pddCover" : "pddCoverSmall"}>
				<img src={pddLogoWhite}/>
				<div className={!smallStyle ? "pddCoverText" : "pddCoverTextSmall"}>
					<h2>The Trusted</h2> 
					<h2>Distribution Partner</h2>
					<h2>To Grow</h2>
					<h2>Your Business</h2>
				</div>
			</div>
			

			<div className={!smallStyle ? "HomePDDInfo" : "HomePDDInfoSmall"} id="PDDIntro">
				<h1>Pasarnow Distribusi Digital</h1>
				<h2>The Trusted Distribution Partner to Grow Your Business</h2>
				<p>Pasarnow Distribusi Digital (“PDD”) is a digitally-enabled distribution company that collaborates with leading manufacturers in Indonesia to distribute quality products to various types of customers.</p>
				<p>Our vision is to become the national champion distribution company in Indonesia, providing nationwide coverage & service excellence, and to be trusted & loved by our Brand Partners.</p>

			</div>

			<img className="warehouseLaptopImage" src={warehouseLaptop}/>

			<div className={!smallStyle ? "valuePropositions" : "valuePropositionsSmall"} id="ValueProposition">
				<h1>PDD Value Propositions</h1>
				<h2>We help Brands & Principals penetrate alternative & mass-market channels more effectively and provide 3 key main value propositions:</h2>
				<div className={!smallStyle ? "valuePropositionImages" : "valuePropositionImagesSmall"}>
					<img src={valueProposition1}/>
					<img src={valueProposition2}/>
					<img src={valueProposition3}/>
				</div>
			</div>

			<div className={!smallStyle ? "OurProducts" : "OurProductsSmall"} id="OurProducts">
				<h1>Our Products</h1>
				<h2>We have proudly serve many renowned & emerging brands in Indonesia since 2021. Through our seamless efficiency, we have successfully distributed over 10 million products through our extensive network.</h2>
				<img src={productImage}/>
				<h2>Our specialty includes Food & Beverages, Health & Personal Care, Baby & Children's Products, and Pet Products.</h2>
			</div>

			<div className="KeyBenefitsBlock" id="KeyBenefits">

				<div className="KeyBenefitsHeader">
					<div className={!smallStyle ? "KeyBenefitsHeaderH1" : "KeyBenefitsHeaderH1Small"}>
						Key Benefits
					</div>
					<div className={!smallStyle ? "KeyBenefitsHeaderH3" : "KeyBenefitsHeaderH3Small"}>
						Power Your Business by Partnering with Us!
					</div>
				</div>

				{/*Benefits List */}

				<div className="BenefitsListBlockTop">
					<div className={!smallStyle ? "BenefitsListBlockColumn" : "BenefitsListBlockColumnSmall"}>
						<div className="BenefitsListBlock">
							<div className="BenefitsListNumber">
								1
							</div>
							<div className="BenefitsListTitle">
								One-Stop Solution
							</div>
							<div className="BenefitsListContent">
							For all your distribution needs: warehousing, sales, delivery, payments, collection & reporting.
							</div>
						</div>

						<div className="BenefitsListBlock">
							<div className="BenefitsListNumber">
								2
							</div>
							<div className="BenefitsListTitle">
								Local Understanding
							</div>
							<div className="BenefitsListContent">
							We possess a deeper understanding of the needs and preferences of the Indonesian consumers, navigating local regulations and requirements more effectively.
							</div>
						</div>

						<div className="BenefitsListBlock">
							<div className="BenefitsListNumber">
								3
							</div>
							<div className="BenefitsListTitle">
								Flexible Solutions
							</div>
							<div className="BenefitsListContent">
							We offer customized solutions to meet your unique distribution needs, from warehousing to transportation.
							</div>
						</div>
					</div>

					<div className={!smallStyle ? "BenefitsListBlockColumn" : "BenefitsListBlockColumnSmall"}>
						<div className="BenefitsListBlock">
							<div className="BenefitsListNumber">
								4
							</div>
							<div className="BenefitsListTitle">
								Market Expansion
							</div>
							<div className="BenefitsListContent">
							Enhance the brand's presence in Indonesia's rapidly growing market. Leverage the local distribution infrastructure to reach consumers nationwide.
							</div>
						</div>

						<div className="BenefitsListBlock">
							<div className="BenefitsListNumber">
								5
							</div>
							<div className="BenefitsListTitle">
								Experienced Team
							</div>
							<div className="BenefitsListContent">
							Our team of experts has decades worth of experience in the industry and is dedicated to ensuring your satisfaction.
							</div>
						</div>

						<div className="BenefitsListBlock">
							<div className="BenefitsListNumber">
								6
							</div>
							<div className="BenefitsListTitle">
								Tech-Enabled Stock Availability
							</div>
							<div className="BenefitsListContent">
							Our tech-enabled system ensure highest-level stock management practices to prevent out of stocks and excess inventory.
							</div>
						</div>

						
					</div>
				</div>
			</div>

			<div className={!smallStyle ? "OurMarketFootprintTop" : "OurMarketFootprintTopSmall"} id="MarketFootPrint">
				<h1>Our Market Footprints</h1>
				<h2>Expand your market presences beyond traditional GT & MT channels</h2>
				<div className={!smallStyle ? "MarketFootPrintRowOne" : "MarketFootPrintRowOneSmall"}>
					<img src={marketFootprintHoreca}/>
					<img src={marketFootprintModernTrade}/>
					<img src={marketFootprintOnline}/>
					<img src={marketFootprintPointOfInterest}/>
				</div>
				<div className={!smallStyle ? "MarketFootPrintRowTwo" : "MarketFootPrintRowTwoSmall"}>
					<img src={marketFootprintSpecialtyStore}/>
					<img src={marketFootprintKoperasi}/>
					<img src={marketFootprintGeneralTrade}/>
				</div>
			</div>

			<div className={!smallStyle ? "distributionCapabilities" : "distributionCapabilitiesSmall"} id="DistributionCapabilities">
				<h1>Our Distribution Capabilities</h1>
				<h2>Expand Your Reach by Tapping into Our Expanding Network</h2>
				<div className={!smallStyle ? "distributionCapabilitiesBlockTop" : "distributionCapabilitiesBlockTopSmall"}>
					<div className={!smallStyle ? "distributionCapabilitiesBlockRow" : "distributionCapabilitiesBlockRowSmall"}>
						<div className={!smallStyle ? "distributionCapabilitiesBlock" : "distributionCapabilitiesBlockSmall"}>
							<img src={warehouseIcon}/>
							<h2>Central Warehouse</h2>
							<label>Depok, West Java</label>
						</div>
						<div className={!smallStyle ? "distributionCapabilitiesBlock" : "distributionCapabilitiesBlockSmall"}>
							<img src={coverageIcon}/>
							<h2>Coverage Area</h2>
							<label>20+ municipalities</label>
						</div>
					</div>
					<div className={!smallStyle ? "distributionCapabilitiesBlockRow" : "distributionCapabilitiesBlockRowSmall"}>
						<div className={!smallStyle ? "distributionCapabilitiesBlock" : "distributionCapabilitiesBlockSmall"}>
							<img src={fleetIcon}/>
							<h2>Internal Fleets</h2>
							<label>25+ Units</label>
						</div>
						<div className={!smallStyle ? "distributionCapabilitiesBlock" : "distributionCapabilitiesBlockSmall"}>
							<img src={salesIcon}/>
							<h2>Sales Personnels</h2>
							<label>40+ personnels</label>
						</div>
					</div>
				</div>

				<div className="distributionCapabilitiesBottomDivider">
					<img src={pasarnowVan}/>
					<div className="FloorBackground">
					</div>
				</div>
			</div>

			<div className={!smallStyle ? "contactUs" : "contactUsSmall"} id="ContactUs">
				<h1>Contact Us</h1>
				<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.4074452256978!2d106.7711968!3d-6.209869599999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f700218507ef%3A0xd49fd0482ef9406c!2sPT%20Anex%20Mitra%20Internusa!5e0!3m2!1sen!2shk!4v1723623462034!5m2!1sen!2shk" className="GoogleMaps" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

				<div className="ContactBodyHome">
					<div className={!smallStyle ? "ContactColumnHome" : "ContactColumnHomeSmall"}>
						<h2>ADDRESS</h2>
						<label>Jl. Panjang Arteri Klp. Dua Raya, No. 61 Blok D-E, RT.1/RW.2, Klp. Dua, Kec. Kb. Jeruk, Kota Jakarta Barat, DKI Jakarta, 11550, Indonesia</label>
					</div>

					<div className={!smallStyle ? "ContactColumnHome" : "ContactColumnHomeSmall"}>
						<h2>E-MAIL</h2>
						<label><a href="mailto:pdd@pasarnow.com">pdd@pasarnow.com</a></label>

					</div>

					<div className={!smallStyle ? "ContactColumnHome" : "ContactColumnHomeSmall"}>
						<h2>WHATSAPP</h2>
						<label><a href="https://wa.me/+6282130009931" target="_blank">+62-821‑3000‑9931</a></label>
					</div>
				</div>
			</div>
			
		</div>
	)
}

export default Distributorship;